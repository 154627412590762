import PropTypes from 'prop-types'
import React from 'react'
import loadable from '@loadable/component'

const Column = loadable(() => import('./Column'))

const PageBuilderColumns = ({ block, raw, index }, props) => {
  const { title, columns } = block

  let columnClasses

  switch (columns.length) {
    case 1:
      columnClasses = 'col-span-12'
      break
    case 2:
      columnClasses = 'col-span-12 md:col-span-6'
      break
    default:
    case 3:
      columnClasses = 'col-span-12 md:col-span-4'
      break
  }

  return (
    <section
      className={`container grid grid-cols-12 mx-auto py-8 md:py-16 relative z-10 ${
        index === 0 ? `mt-16 md:mt-20` : ``
      }`}
    >
      <h2 className="col-span-12 xl:col-span-10 xl:col-start-2 text-17 md:text-18 font-bold">
        {title}
      </h2>

      {columns && (
        <div className="col-span-12 xl:col-start-2 xl:col-span-10 grid grid-cols-12 md:gap-12 lg:gap-16">
          {columns.map((column, columnIndex) => (
            <Column
              columnClasses={columnClasses}
              column={column}
              key={columnIndex}
            />
          ))}
        </div>
      )}
    </section>
  )
}

PageBuilderColumns.propTypes = {
  block: PropTypes.object,
  raw: PropTypes.object,
  index: PropTypes.number,
}

PageBuilderColumns.defaultProps = {
  block: {},
  raw: {},
  index: 0,
}

export default PageBuilderColumns
